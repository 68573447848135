
  <tr>
    <th *ngFor="let header of headers; let i = index;" colspan="2" [colSpan]="!header.colspan ? 1 : header.colspan" [class]="header.class">
      <a *ngIf="header.sortColumn" href="#"
        (click)="$event.preventDefault(); onSortOrderChange(header.sortColumn);">
        {{ header.title | localize }} 
        <i *ngIf="header.sortColumn !== sortColumn" class="fa fa-sort"></i>
        <i *ngIf="header.sortColumn === sortColumn && sortOrder === 'asc'" class="fa fa-sort-up"></i>
        <i *ngIf="header.sortColumn === sortColumn && sortOrder === 'desc'" class="fa fa-sort-down"></i>
      </a>
      <span *ngIf="!header.sortColumn">{{ header.title | localize }}</span>
    </th>
  </tr>
