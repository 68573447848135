// "Production" enabled environment

export const environment = {
    production: true,
    hmr: false,
    appConfig: 'appconfig.production.json',
    defaults: {
        dateLocale: 'en-gb'
    }
};
