import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[pageTitle]'
})
export class PageTitleDirective implements OnInit {
  @Input() pageTitle: string;

  constructor(private el: ElementRef) {

  }

  ngOnInit(): void {
    const self = this;
    document.getElementById('page-title').textContent = self.pageTitle;
  }

}
