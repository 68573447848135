  <pagination-template
    #p="paginationApi"
    [id]="id"
    [maxSize]="maxSize"
    (pageChange)="pageChange.emit($event)"
  >
    <ul class="pagination">
      <li *ngIf="directionLinks" class="page-item" [class.disabled]="p.isFirstPage()">
        <a href="javascript:;" *ngIf="p.isFirstPage()" class="page-item page-link page-first">
          <i class="fas fa-angle-double-left"></i></a>
        <a href="javascript:;" *ngIf="!p.isFirstPage()" (click)="p.setCurrent(1)" class="page-link page-first">
          <i class="fas fa-angle-double-left"></i>
        </a>
      </li>
      <li *ngIf="directionLinks" class="page-item" [class.disabled]="p.isFirstPage()">
        <a href="javascript:;" *ngIf="p.isFirstPage()"  class="page-link page-prev">
          <i class="fas fa-angle-left"></i>
        </a>
        <a href="javascript:;" *ngIf="!p.isFirstPage()" (click)="p.previous()" class="page-link page-prev">
          <i class="fas fa-angle-left"></i>
        </a>
      </li>
      <!-- <span class="page-display"><span class="page-num">1</span> of {{ totalRecordCount }} </span> -->
      <span class="page-display">{{ paginationLabel }} </span>


      <li *ngIf="directionLinks" class="page-item" [class.disabled]="p.isLastPage()">
        <a href="javascript:;" *ngIf="p.isLastPage()"  class="page-link page-next">
          <i class="fas fa-angle-right"></i>
        </a>
        <a href="javascript:;" *ngIf="!p.isLastPage()" (click)="p.next()" class="page-link page-next">
          <i class="fas fa-angle-right"></i>
        </a>
      </li>
      <li *ngIf="directionLinks" class="page-item" [class.disabled]="p.isLastPage()">
        <a href="javascript:;" *ngIf="p.isLastPage()"  class="page-link page-last">
          <i class="fas fa-angle-double-right"></i>
        </a>
        <a href="javascript:;" *ngIf="!p.isLastPage()" (click)="p.setCurrent(p.getLastPage())" class="page-link page-last">
          <i class="fas fa-angle-double-right"></i>
        </a>
      </li>
    </ul>
    
  </pagination-template>
