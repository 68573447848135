import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({name: 'localizeDate'})
export class LocalizeDatePipe implements PipeTransform {

    transform(dt: moment.Moment, format?: string): string {
        format = (format === undefined) ? 'DD/MM/YYYY' : format;
        return moment.tz(dt, moment.tz.guess()).format(format);
    }
}