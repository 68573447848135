import { Directive, ElementRef, OnInit, Input, AfterViewInit } from '@angular/core';
import { table } from 'console';

@Directive({
  selector: '[appLayoutInitializer]'
})
export class LayoutInitializerDirective implements OnInit, AfterViewInit {
  @Input() bodyId: string;
  @Input() bodyClass: string;
  private el: any;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  ngOnInit(): void {
    const self = this;

    $(window).on('resize', () => {
      self.initActivityLogHeight();
    });

    $(document).on('click', function (e) {
      if ($(e.target).closest('.menu-container.menu-open').length === 0 &&
          $(e.target).closest('.menu-toggler-link').length === 0) {
        const btnToggler = $('.menu-toggler-link.menu-open');
        const sideNav = $('.menu-container.menu-open');
        if (sideNav.length > 0) {
          btnToggler[0].click();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    const self = this;
    setTimeout(() => {
      self.initActivityLogHeight();
    }, 250);
  }

  private initActivityLogHeight() {
    let tablesColHeight = $('#dashboard .tables-col .col-content').innerHeight() - 50;
    tablesColHeight = tablesColHeight > 500 ? tablesColHeight : 500;
    $('#dashboard .activity-log-content').height(tablesColHeight);

  }
}
