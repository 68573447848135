import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'localCurrency',
})
export class LocalCurrencyPipe implements PipeTransform {
  transform(value: number, digitsInfo = '1.2-2', locale = 'en-US'): string | null {
    return formatNumber(value, locale, digitsInfo);
  }
}
