import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[bodyId]'
})
export class BodyIdDirective implements OnInit {
  @Input() bodyId: string;

  constructor(private el: ElementRef) {

  }

  ngOnInit(): void {
    const self = this;
    document.body.id = self.bodyId;
  }

}
